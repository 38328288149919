//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab ($number-of-cards: 2,
    $prefab: 2);

  // Choose your card style for home feature cards
  .homeFeature {
    @include card(
      $card-heading-colour: white,
      $card-hover-details-background-colour: rgba($brand-blue, 0.8),
      $card-hover-image-opacity: 1.0,
      $card-hover-summary-colour: white,
      $card-summary-font-size: 1.2rem,
      $card-text-align: center,
      $card-border: 0,
      $card-border-radius: 5px
    )
    @media (min-width: 1025px) {
      @include card-text-over-image();
    }
    @media (max-width: 1024px) {
      @include card(
        $card-heading-colour: $brand-primary
      )
      @include card-basic()
    }
  }
}

.featureSection1 {
  @include cards-layout-prefab ($number-of-cards: 3,
    $prefab: 1)
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

body:not(.subsite) {
  // Choose your overall home feeds layout
  @include home-feeds-prefab ($number-of-feeds: 2,
    $prefab: 1);

  // Specify a card layout for each individual feed
  .homeFeedBox1 .feedList {
    @include cards-layout-prefab ($number-of-cards: 6,
      $prefab: 3);
  }

  .homeFeedBox2 .feedList {
    @include cards-layout-prefab ($number-of-cards: 3,
      $prefab: 2);
  }

  // Choose your card style for home feed cards
  .feedItem {
    @include card($card-border: 0,
      $card-heading-colour: white,
      $card-heading-font-size: 1.5rem,
      $card-summary-colour: white,
      $card-hover-heading-colour: white,
      $card-hover-summary-colour: white);
    @include card-text-overlay;
  }

  .homeFeedBox1 {
    @include home-feed-post-icons(
      $icon-position-x: 'right',
      $icon-position-y: 'top',
      $news-colour: $brand-secondary
    );
  }
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
body:not(.member-resources) {
  .listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
    @include card;
    @include card-basic;
  }
}

// Unique design for Member Resources page (/pages/category/member-resources)
body.member-resources {
  .listedPost {
    @include card($card-heading-colour: white,
      $card-hover-details-background-colour: rgba($brand-blue, 0.8),
      $card-hover-image-opacity: 1.0,
      $card-hover-summary-colour: white,
      $card-summary-font-size: 1.2rem,
      $card-text-align: center,
      $card-border: 0,
      $card-border-radius: 5px);
    @include card-text-over-image(
      $card-text-over-image-darken-image-amount: 0.65
    );
  }
}

//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}

//
// Subsite feeds
//

body.subsite {
  @include home-feeds-prefab (
    $number-of-feeds: 2,
    $prefab: 1
  );
  .feedList {
    @include cards-layout-prefab (
      $number-of-cards: 3,
      $prefab: 1
    );
    .feedItem {
      @include card;
      @include card-basic;
    }
  }
}