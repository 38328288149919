$platform-name: 'adoptionukcharity';

//
// Colours
//

$brand-primary: #440099;
$brand-secondary: #F4364C;
$brand-secondary-text-contrast-override: null !default;
$brand-green: #34B78F;
$brand-blue: #0084D5;

// Create greyscale
$black: #000 !default;
$grey-dark: #292b2c !default;
$grey: #464a4c !default;
$grey-light: #636c72 !default;
$grey-lighter: #CFCFD5 !default;
$grey-lightest: #f7f7f9 !default;
$white: #fff !default;

$donate-colour: $brand-secondary !default;

//
// Layout
//

$container-max-width: 1440px;
$border-radius: 5px;
$inner-container-max-width: 1260px;

// Spacers
$spacer: 1rem;

//
// Logo
//

$logo-width: 250px;

//
// Typography
//

$font-family-base: "Cabin", sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25rem;
$font-size-small: 0.8rem;

//
// Header
//

// Search - input
$header-search-input-height: 40px;
$header-search-input-margin-right: 0;
$header-search-input-placeholder-colour: $grey-lighter;
$header-search-input-border-width: 0;
$header-search-input-background-colour: $grey-lightest;

// Search - button
$header-search-button-background-colour: $header-search-input-background-colour;
$header-search-button-icon-colour: text-contrast(
  $header-search-button-background-colour
) !default;

//
// Navigation
//

$nav-background-colour: transparent;

// Nav normal
$nav-normal-align-items: right;

$nav-top-level-item-hover-colour: #fff;
$nav-top-level-item-hover-background-colour: $brand-primary;

$nav-submenu-item-hover-colour: #fff;
$nav-submenu-item-hover-background-colour: $brand-primary;

//
// Carousel
//

$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
//$carousel-image-overlay: linear-gradient(to right, #000 0%, rgba(0,0,0,0) 70%);
$carousel-details-position-y: bottom;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 600px;
$carousel-heading-colour: #fff;
$carousel-summary-colour: #fff;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: false;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: split;
$carousel-controls-button-background-colour: $brand-secondary;
$carousel-controls-icon-colour: #fff;
$carousel-controls-button-border-radius: 100%;

$carousel-dots-position-y: $carousel-controls-position-y;
$carousel-dots-position-x: left;
$carousel-dots-active-colour: $carousel-controls-button-background-colour;
$carousel-dots-padding: 2rem 2rem 2rem 6rem;

//
// Home intro
//

$home-intro-background-colour: white;
$home-intro-text-align: left;
$home-intro-font-weight: regular;
$home-intro-font-size: 1.5rem;
$home-intro-color: $brand-primary;

$home-intro-margin-top: 0;
$home-intro-padding-y: $spacer * 3;
$home-intro-content-max-width: $inner-container-max-width;

//
// Buttons
//

$btn-font-size: $font-size-large;
$btn-font-weight: bold;

$social-icons-border-radius: 100%;

//
// Home features
//

$home-features-max-width: $container-max-width !default;
$home-features-background-colour: $brand-secondary;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 3;

//
// Impact stats
//

$impact-stats-background-image: "motif-backdrop.svg";
$impact-stats-direction: row;

$impact-stats-heading-margin-bottom: 0;

//
// Card
//

$card-hover-image-opacity: 1.0;
$card-hover-image-scale: 1.1;

//
// Menu admin
//

$menu-admin-background-colour: $grey-lightest;

//
// Blockquote
//

$blockquote-padding: $spacer * 4 $spacer * 2;

//
// Donation form
//

$donation-form-layout: columns !default; // rows or columns
$donation-form-banner-enabled: true !default;
$donation-form-frequency-tabs: true;

//
// Subsite
//

$subsite-enabled: true;
$subsite-logo: 'title';

//
// Sidebar
//

$sidebar-enabled: false;

//
// Posts
//

$post-content-max-width: $inner-container-max-width;

//
// Footer
//

$footer-contents-max-width: $inner-container-max-width;

//
// Shop
//

$basket-link-only-on-shop-pages: true;

$comment-add-image-enabled: false;