@mixin header-arrow($width: 16px, $margin-left: 0) {
  &:after {
    content: url($assets-path + 'arrow-pointer-only.svg');
    width: $width;
    display: inline-block;
    margin-left: $margin-left
  }
}

// Header search
.header-search #siteSearch {
  background-color: $grey-lightest;
}

// Intro with buttons
.homeIntro {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  p:last-of-type {
    margin-bottom: 0;
  }

  div {
    width: 70%;
    padding-right: 100px;
  }

  table {
    width: 30% !important;
    display: block;
    border: 0px;
    margin-top: 0;

    tbody,
    tr,
    td {
      border: 0px;
    }

    td {
      padding: 0.4rem;
    }

    a {
      font-size: $btn-font-size;
      width: 100%;
    }
  }
}

// Arrow buttons
a.arrow,
#donateBtn,
.cta-private {
  border-radius: $btn-border-radius 0 0 $btn-border-radius;

  &:after {
    content: "";
    position: absolute;
    right: -50%;
    width: 50%;
    height: 100%;
    background-size: auto auto;
    background-repeat: no-repeat;
    transition: all 100ms;
  }

  &.purple {
    background: $brand-primary;

    &:after {
      background-image: url($assets-path + 'arrow-purple.svg');
    }
  }

  &.green {
    background: $brand-green;

    &:after {
      background-image: url($assets-path + 'arrow-green.svg');
    }
  }

  &.red,
  &.callToActionBtn {
    background-color: $brand-secondary;

    &:after {
      background-image: url($assets-path + 'arrow-red.svg');
    }
  }

  &:hover {
    border-radius: $btn-border-radius;

    &:after {
      right: calc(-50% - 5px);
      transition: all 100ms cubic-bezier(.17, .67, .46, 2.6);
    }
  }
}

body .cta-private {
  display: none;
}

body.userLoggedIn .cta-private {
  display: inline-flex;
  position: relative;
}

.cta-private {
  background-color: $brand-primary;
  padding: 0;

  a {
    padding: 10px 20px;
  }

  &:after {
    background-image: url($assets-path + 'arrow-purple.svg');
  }
}

.cta-private a {
  color: #fff;
  font-size: 1.25rem;
}

// Nav in line with logo
#menuMain {
  margin-top: -55px;
}

#pageHeader>div.content.headerContent>div,
#pageHeader>div.content.headerContent>form {
  margin-top: -30px;
}

// Home intro colour (variable isn't working and I don't have time to figure out why)
.homeIntroWrapper *:not(a) {
  color: $brand-primary;
}



// Carousel decoration
.carouselDetailWrapper {
  //background-image: url($assets-path + 'carousel-motif.svg');
  background-repeat: no-repeat;
  background-size: auto 101%;
  background-position: right;
  max-width: 100%;
}

.carouselSlideDetail {
  margin-left: 5rem;
  margin-bottom: 5rem;
}

// Carousel controls
.carouselControls {
  justify-content: center;
  padding-bottom: 5px;

  li:first-child {
    margin-left: 130px;
  }
}

// Home feature section titles
.featureSection1 h2 {
  width: 100%;

  @media (min-width: 1025px) {
    margin-left: 15px;
  }

  color: #fff;
}

// Backdrop blur behind summary
.featureSection1 .homeFeatureDetailsWrapper p,
.member-resources .listedPost .postSummary {
  backdrop-filter: blur(5px);
}

// Stats
.homefeaturecategory-homeboximpactstats {
  table {
    display: flex;
    flex-direction: row;

    tbody tr {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      td,
      td h2 {
        width: fit-content !important;
      }
    }
  }
}

// Stat icons
// Modify the mixin for this
@mixin impact-stat-icon ($stat-number,
  $image,
  $width,
  $height) {
  .homefeaturecategory-homeboximpactstats tr:nth-child(#{$stat-number}) td:last-child {
    &:before {
      content: '';
      width: 100%;
      height: $height;
      display: block;
      background-image: url($assets-path + $image);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin: $spacer*2 0;
    }
  }
}

$impact-stat-icon-size: 100px;

.homefeaturecategory-homeboximpactstats tr:before {
  margin-bottom: 10px;
}

@include impact-stat-icon($stat-number: 1, $image: "stat-icon-2.png", $width: $impact-stat-icon-size, $height: $impact-stat-icon-size);
@include impact-stat-icon($stat-number: 2, $image: "stat-icon-3.png", $width: $impact-stat-icon-size, $height: $impact-stat-icon-size);
@include impact-stat-icon($stat-number: 3, $image: "stat-icon-1.png", $width: $impact-stat-icon-size, $height: $impact-stat-icon-size);

.homefeaturecategory-homeboximpactstats td:last-child {
  display: flex;
  flex-direction: column;

  p:last-child {
    margin: auto;
  }
}

// Feed card details blur
body:not(.subsite) .homeFeedBox1 [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
  backdrop-filter: blur(5px);
}

// Blockquotes
blockquote {
  background-image: url($assets-path + 'motif-backdrop.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

// Subsites
body.subsite {
  &.northern-ireland {
    .mainLogo {
      background-image: url($assets-path + 'logo-tagline.svg');
    }
  }

  &.scotland {
    .mainLogo {
      background-image: url($assets-path + 'logo-scotland.svg');
    }
  }

  &.wales {
    .mainLogo {
      background-image: url($assets-path + 'logo-wales.svg');
    }
  }

  &.fasd-hub {
    .mainLogo {
      background-image: url($assets-path + 'logo-subsite-fasd.png');
    }
  }

  .subsiteBody {
    padding-top: 0;
  }

  // Uncomment below if they don't want subsite nav to hijack main nav.
  // &.homepage {
  //     .contentContainer .menuSub {
  //         order: 2;
  //     }
  //     .contentContainer .headerText {
  //         order: 3;
  //     }
  // }
  .homeFeed {
    padding-top: 0 !important;
  }

  @media (min-width: 1024px) {
    .feedList .feedItem {
      max-width: calc((100% / 3) - 30px);
    }
  }
}

.feedTitle a:hover {
  text-decoration: none;
}

// // Background for all home features - mockup sent, enable if they like it, delete if not.
// .homeFeaturesWrapper {
//     position: relative;
//     &:before {
//         content: '';
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
//         bottom: 0;
//         background-image: url(../assets/motif-backdrop.svg);
//         z-index: 0;
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-position: center;
//         opacity: 0.5;
//     }
// }

// Subsite intro styling, see script for wrapper creation - mockup sent, delete if unwanted
.subsiteIntro {
  background-color: $brand-primary;
  padding-top: $spacer * 5;
  padding-bottom: $spacer * 5;
  position: relative;
  margin-bottom: $spacer * 5;

  * {
    color: #fff !important;
  }

  a {
    text-decoration: underline;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../assets/motif-backdrop.svg);
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    opacity: 0.5;
  }
}

.subsiteFeedsWrapper {
  max-width: $container-max-width;
  margin: auto;
}

body.subsite .feedsTitle {
  width: fit-content;
  margin-left: 0;
  margin-right: 0;

  @include header-arrow();

}

// Hide newsletter, for now.
.footerBox[class*="ewsletter"] {
  display: none;
}

// Only show share on news and blogs
.shareWrapperContainer,
.shareWrapper {
  display: none;
}

body.blogsPostBody,
body.newsPostBody {

  .shareWrapperContainer,
  .shareWrapper {
    display: block;
  }
}

// Cloned share wrapper
.shareWrapperContainer {
  background-color: $brand-primary;
}

.headerText .shareWrapper {
  max-width: $post-content-max-width;
  margin: auto;
  padding: 20px;

  * {
    color: #fff;
  }
}

.postFooter .shareWrapper {
  padding: 20px;
  background: $grey-lightest;

  strong {
    @include header-arrow(14px, 10px);
  }
}

// Fix search bar border radius
.header-search #siteSearch {
  border-radius: $border-radius 0 0 $border-radius;
}

.header-search button {
  border-radius: 0 $border-radius $border-radius 0
}

// Mobile fixes
@media (max-width: 1024px) {
  .carouselSlideDetail {
    margin-left: 0;
    margin-bottom: 0;
  }

  .homeIntro div {
    width: 100%;
    padding-right: 0;
  }

  .homeIntro table {
    width: 100% !important;

    tbody {
      display: flex;
      flex-wrap: wrap;

      td {
        padding: 10px 20px;
      }
    }
  }
}

//  Header buttons
.mainCallToAction .cta-button:not([class*="donate"]).forum {
  background-color: $brand-green;
}

// Stop nav overlapping logo
@media (min-width: 1025px) and (max-width: 1400px) {
  .menuMain .topLevel li.level1>a {
    font-size: 1.1vw;
  }
  .mainLogo {
    height: 80px;
  }
  .headerContent {
    height: 120px;
  }
}

// Carousel border radius
.carouselSlide {
  border-radius: 0;
}

// Menu admin
#menuAdmin li {
  padding: 0 0.75rem;
}

// Nav hover fix
nav a {
  transition: color 150ms, background 150ms;
}

// Footer tidyup
@media (min-width: 769px) {
  .footerBox2 {
    grid-column: 3 / span 3;
  }

  .footerBox3 {
    grid-column: 6 / span 5;
  }

  .footerBox4 {
    grid-column: 11 / span 4;
  }
}

// Footer border
.pageFooter {
  border-top: 5px solid $brand-primary;
}

// Subsite card fixes (there are a lot)
@media (min-width: 1024px) {
  body.subsite .feedList .feedItem {
    min-width: calc((100% / 3) - 30px);
  }
}

// Subsite footer content
body:not(.wales) .welshSubsiteContent {
  display: none;
}

body.wales .nonWelshSubsiteContent {
  display: none;
}

// Admin on mobile
@media (max-width: 500px) {
  #menuAdmin a {
    font-size: 0.6rem;
  }
}

// Carousel on mobile
@media (max-width: 1024px) {
  .carouselButtons {
    padding: 2rem 2rem 2rem 8rem;
  }
}

// Membership stuff DES-5127
body.donate[class*="membership"] {

  .donationFrequency,
  .selectDonationAmount > span:nth-child(1),
  .donationAmountOther,
  .donationPeriod,
  .donationDate,
  .displayName,
  .donationComment,
  .whyDonate {
    display: none;
  }
}

// Show blog and news publish dates CR-67
.blogsPostBody, .newsPostBody {
  .post .postContent .postFooterWrapper .postFooter>*:not(.shareWrapper).publishDetailsWrapper {
    display: block;
    >*:not(.publishDatesWrapper) {
      display: none;
    }
  }
}

// Show subsite body everywhere
.subsite.fasd-hub:not(.homepage) .subsiteBody {
  display: block;
}

// 523815 Remove the log in/log out link at the top
.menuAdminContainer li.menuAdminLogin {
  display: none;
}
.menuAdminContainer li.menuAdminLogOut {
  display: none;
}